// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Auth/AuthContext/AuthContext.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Auth/AuthContext/AuthContext.tsx");
  import.meta.hot.lastModified = "1728334464000";
}
// REMIX HMR END

import { createContext, useContext } from 'react';
const AuthContext = createContext({
  user: null,
  isLoggedIn: false
});
export const AuthProvider = ({
  children,
  user,
  isLoggedIn
}) => {
  const value = {
    isLoggedIn,
    user
  };
  //
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
_c = AuthProvider;
export const useAuth = () => {
  _s();
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
_s(useAuth, "b9L3QQ+jgeyIrH0NfHrJ8nn7VMU=");
var _c;
$RefreshReg$(_c, "AuthProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;